
import { Component, Prop, Vue } from "vue-property-decorator";
import { TeamFunctions } from "@/store/modules/activity-account.store";

@Component({
    components: {
        userImage: () => import("@/components/general/user-image.vue"),
        ElCard: () => import("@/views/seller/parts/general/el-card.vue"),
    },
})
export default class PageSellerPartContactCard extends Vue {
    @Prop({ required: true }) contact!: ActivityAssignedTo;

    get label() {
        if (!this.contact) {
            return;
        }

        return this.$t(`views.seller.contacts.functions.${this.contact.type}`);
    }

    get description() {
        if (!this.contact) {
            return;
        }

        return this.$t(`views.seller.contacts.function_descriptions.${this.contact.type}`);
    }
}
